import React, { useState } from "react";
import Layout from "../../Layout";
import "./style.scss";
import SEO from "../../seo";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function HallOfFame() {
  // const [] = useState()
  const [currentIndex, setCurrentIndex] = useState(0);

  const settings = {
    // dots: true,
    infinite: true,
    arrows: true,
    // fade: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const dataSchoolAwards = [
    {
      des: "Chinmaya Vidyalaya, New Delhi ranked No. 18 in India and No. 5 in New Delhi 2020 by Education Today Survey in the category – ‘India’s Top 20 CBSE Schools’.",
      images: [
        "https://www.chinmayavvdelhi.ac.in/images/Blackele/award1.png",
        "https://www.chinmayavvdelhi.ac.in/images/Blackele/award2.png",
      ],
    },

    {
      des: "The Vidyalaya has received the International Dimension in School Certification 2020-23 conferred by the British Council, New Delhi, for the third time in a row.",
      images: ["https://www.chinmayavvdelhi.ac.in/images/Blackele/award4.jpg"],
    },

    {
      des: "Chinmaya Vidyalaya, New Delhi Ranked 1st in ‘Challengers Category’, in South-West Delhi Zone, during ‘Times Of India’ survey 2020 for the second consecutive year.",
      images: ["https://www.chinmayavvdelhi.ac.in/images/Blackele/award3.jpg"],
    },

    {
      des: "Vidyalaya received World Award for Value Education (WAVE) during World Assembly on Value Education on 13th March 2020 in the main auditorium at India International Centre, 40 Lodi Estate, New Delhi.",
      images: [
        "https://www.chinmayavvdelhi.ac.in/images/Blackele/S10.png",
        "https://www.chinmayavvdelhi.ac.in/images/Blackele/S11.png",
      ],
    },

    {
      des: "Vidyalaya received Brainfeed Award 2019 on 6th February 2020 in the categories of Best CBSE Schools, Innovative Practices, Inspirational Leadership, Life Skills Education, Safety and Security for enriching the standards in Imparting Excellence Education to the GenNext learners.",
      images: [
        "https://www.chinmayavvdelhi.ac.in/images/Blackele/S8.png",
        "https://www.chinmayavvdelhi.ac.in/images/Blackele/S9.png",
      ],
    },

    {
      des: "‘Chinmaya Vidyalaya’ is Ranked No.1 in India under the Top CBSE School - Parameter wise for “Individual Attention to Students” in a survey conducted by EducationToday on 13th December 2019 at The Chancery Pavilion, Bangalore.",
      images: [
        "https://www.chinmayavvdelhi.ac.in/images/Blackele/S4.png",
        "https://www.chinmayavvdelhi.ac.in/images/Blackele/S5.png",
      ],
    },

    {
      des: "Chinmaya Vidyalaya received 2019 Global Human Rights Protection Award on 10th December 2019 organised by Indian Institute Of Human Rights at main auditorium, India International Centre, 40 Lodhi Estate, New Delhi-110003.",
      images: ["https://www.chinmayavvdelhi.ac.in/images/Blackele/S7.png"],
    },

    {
      des: "Chinmaya Vidyalaya received the internationally acclaimed Global Innovative School Awards (GISA), Dubai 2019 on 23rd November 2019.",
      images: ["https://www.chinmayavvdelhi.ac.in/images/Blackele/S6.png"],
    },

    {
      des: "Chinmaya Vidyalaya received 2nd National School Education Awards 2019 on 15th October 2019 for Excellent School for Excellence in Student Teacher Interaction CMAI Association Of India.",
      images: [
        "https://www.chinmayavvdelhi.ac.in/images/Blackele/S2.png",
        "https://www.chinmayavvdelhi.ac.in/images/Blackele/S3.png",
      ],
    },

    {
      des: "Chinmaya Vidyalaya received an Award in the category of India’s Top 50 Prestigious School Jury Awards 2019 : “Great Legacy in Education (K-12)” by Education Today.",
      images: ["https://www.chinmayavvdelhi.ac.in/images/Blackele/S1.png"],
    },
  ];

  const dataTeacherAwards = [
    {
      des: "Ms. Anila S. Nath (PGT Economics) and Ms. Asha Shahi (TGT Sanskrit) are proud recipients of National Teacher Award 2019 conferred by Centre for Education Development Foundation.",
      images: [
        {
          img: "https://www.chinmayavvdelhi.ac.in/images/Blackele/T1.png",
          des: "National Creative Teacher Award Ms. Anila S. Nath (PGT Economics)",
        },
        {
          img: "https://www.chinmayavvdelhi.ac.in/images/Blackele/T2.png",
          des: "National Innovative Teacher Award Teaching Ms. Asha Shahi (TGT Sanskrit)",
        },
      ],
    },

    {
      des: "Mr. Pratap Singh Bisht, Physical Education Teacher of the Vidyalaya received the Best Coach Award Category for the Dr. Ajmer Singh Award.",
      images: [
        {
          img: "https://chinmayavvdelhi.ac.in/assets/ajmeraward1.png",
          des: "",
        },
        {
          img: "https://chinmayavvdelhi.ac.in/assets/ajmeraward2.jpeg",
          des: "",
        },
      ],
    },
  ];

  const school = (
    <div className="halloffame__container">
      {dataSchoolAwards.map((item) => (
        <div className="halloffame__card">
          <div>
            <Slider {...settings}>
              {item.images.map((item, i) => (
                <div
                  // style={{ display: "inline-block" }}
                  className="halloffame__card__slider__imgcontainer"
                >
                  <img
                    key={`herodesk-${i}`}
                    alt="Hero Image"
                    loading="eager"
                    src={item}
                  ></img>
                </div>
              ))}
            </Slider>
          </div>

          <div className="halloffame__card__text">{item.des}</div>
        </div>
      ))}
    </div>
  );

  const teacher = (
    <div className="halloffame__container">
      {dataTeacherAwards.map((item) => (
        <div className="halloffame__card">
          <div>
            <Slider {...settings}>
              {item.images.map((item, i) => (
                <div
                  // style={{ display: "inline-block" }}
                  className="halloffame__card__slider__imgcontainer"
                >
                  <img
                    key={`herodesk-${i}`}
                    alt="Hero Image"
                    loading="eager"
                    src={item.img}
                  ></img>
                </div>
              ))}
            </Slider>
          </div>

          <div className="halloffame__card__text">{item.des}</div>
        </div>
      ))}
    </div>
  );

  let uniformJSX = null;

  if (currentIndex === 0) {
    uniformJSX = school;
  } else if (currentIndex === 1) {
    uniformJSX = teacher;
  }

  return (
    <Layout>
      <SEO
        title="Hall of Fame | Chinmaya Vidyalaya"
        description="Explore the accolades and prestigious awards received by the faculty and school."
        img="https://www.chinmayavvdelhi.ac.in/images/Blackele/Chinmaya_link_preview.jpg"
        keywords=""
      />
      <div className="halloffame">
        <h1 className="heading">Hall Of Fame</h1>

        <div className="halloffame__tabs">
          <div
            className={`halloffame__tabs__button ${
              currentIndex === 0 && "halloffame__tabs__button--active"
            }`}
            onClick={() => setCurrentIndex(0)}
          >
            School Awards
          </div>
          <div
            className={`halloffame__tabs__button ${
              currentIndex === 1 && "halloffame__tabs__button--active"
            }`}
            onClick={() => setCurrentIndex(1)}
          >
            Teacher Awards
          </div>
        </div>

        {uniformJSX}
      </div>
    </Layout>
  );
}
